import React, { useRef, useState } from 'react';

import emailjs from '@emailjs/browser';

const Result = () =>{
    return(
      <p className='email-message'>Your message has been succesfully sent. We will contact you soon!</p>
    )
}

const Form = () => {
  const form = useRef();


  const [result, showResult] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_4b80vqd', 'template_viqqxjz', form.current, 'PZKjbzpzI8mixEonN')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      showResult(true)
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
        <div className="form-field">
            <input type="text" name="name" placeholder="name *" required />
        </div>
        <div className="form-field">
            <input type="bname" name="company" placeholder="company / organisation" />
        </div>
        <div className="form-field">
            <input type="email" name="email" placeholder="email *" required/>
        </div>
        <div className="form-field">
            <input type="phone" name="phone" placeholder="phone" />
        </div>
        <div className="form-field">
            <select name="service" defaultValue={'DEFAULT'}>
            <option value="DEFAULT" disabled >select services</option>
                <option value="website">website</option>
                <option value="graphic">graphic</option>
                <option value="branding">branding</option>
            </select>
        </div>
        <div className="form-field">
            <textarea name="message" rows="7" placeholder="message" />
        </div>
        <div className="form-submit">
            <input className="submit-button" type="submit" value="submit" ></input>
        </div>

        <div className="sent-row"> 
            {result ? <Result /> : null}
        </div>
      
    </form>
  );
};

export default Form