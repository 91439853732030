import React , {useEffect, useRef} from "react";
import './home.css';
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';

const Home = ({ filterItem}) => {

    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({
        behaviour: "smooth"
      }) 

      useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      

    return (
        <>
            <section className="home-section home-section-1">
                <div className="home-section-wrapper wrapper" >
                    <div className="home-about" >
                        <div className="home-about-text">
                            <h2>our objective</h2>
                            <p>we make sure<br/>all of our creations<br/>are tasty and juicy.</p>
                        </div>
                 
                        <div className="home-about-more-container">
                            
                        </div>
                  
                    </div>
                </div>
                <span className="home-about-more" onClick={executeScroll}>read more<br/>about us</span>
            </section>
            
            <section className="home-section home-section-2" ref={myRef}>
                <div className="home-section-wrapper wrapper" data-aos="fade-up" data-aos-duration="1000">
                    <div className="home-section-intro">
                        <h2>services</h2>
                        <p>We'll work closely with you to understand your needs, ensuring the design and backend are built just the way you want them.</p>
                    </div>
                    <div className="home-section-details">
                        <div className="home-service home-service-1">
                            <h3><i></i>digital</h3>
                            <ul>
                                <li>digital & website design</li>
                                <li>user experience design</li>
                                <li>information architecture</li>
                                <li>digital strategy</li>
                                <li>interface design</li>
                                <li>analytics</li>
                                <li>responsive front-end</li>
                                <li>social channels</li>
                                <li>e-commerce development</li>
                            </ul>
                        </div>
                        <div className="home-service home-service-2">
                            <h3><i></i>graphic</h3>
                            <ul>
                                <li>brand & identity creation</li>
                                <li>logo design</li>
                                <li>illustration</li>
                                <li>retail environments</li>
                                <li>signage & wayfinding systems</li>
                            </ul>
                        </div>
                        <div className="home-service home-service-3">
                            <h3><i></i>print</h3>
                            <ul>
                                <li>poster design</li>
                                <li>brochure design</li>
                                <li>stationery design</li>
                                <li>editorial</li>
                                <li>packaging</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="home-half-watermelon">

                </div>
            </section>

            <section  className="home-section home-section-3">
                <div className="home-section-wrapper wrapper" >
                    <div className="home-section-intro" data-aos="fade-up" data-aos-duration="1000">
                        <h2>clients</h2>
                    </div>
                    <div className="home-section-details" data-aos="fade-up" data-aos-duration="1000">
                        <p>Starting our journey in 2013, Molto Digital Limited has been dedicated to excellence in web design, development, and graphic design.</p>
                        <p>We take pride in serving a diverse clientele, from individuals and non-profit arts organizations to prestigious institutions and corporations.</p>
                        <p>We strive to deliver digital and print solutions that seamlessly blend functionality, visual appeal, and user-centric design.</p>
                        <div className="home-section-more-container">
                            <Link className="home-section-more" to="/contact">let's talk</Link>
                           
                        </div>
                    </div>
                </div>
            </section>

            <section data-aos="fade-up" data-aos-duration="2000" className="home-section home-section-4">
                <div className="home-section-wrapper wrapper">
                    <div className="home-logos">
                        <div className="home-logo"><img src={"/clientImage/client-1.png"} alt="Le French May Arts Festival" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-2.png"} alt="Vivat recording label" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-3.png"} alt="The King's Consort" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-4.png"} alt="Music Children Foundation" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-5.png"} alt="Hong Kong Wind Philharmonia" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-6.png"} alt="Hong Kong Saxophone Ensemble" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-7.png"} alt="Barquentine Choral Artists" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-8.png"} alt="Kenko 708" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-9.png"} alt="Canary Classics" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-10.png"} alt="Financial Times" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-11.png"} alt="Hong Kong Band Directors Association" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-12.png"} alt="Ho Cheung Shuk Yuen Charitable Foundation" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-13.png"} alt="Jean CK Ho Family Foundation" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-14.png"} alt="Initial-P Percussion Studio" /></div>
                        <div className="home-logo"><img src={"/clientImage/client-15.png"} alt="Friends of Hong Kong Museum of Art" /></div>
                    </div>
                </div>
            </section>
        
        </>
    )
}

export default Home
