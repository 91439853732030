import React, {useState } from "react";
import { Link } from 'react-router-dom';
import Logo from '../../../images/logo.png'
import brand from '../../../images/nav-brand.png'
import {NavLink} from 'react-router-dom'
import './navbar.css';

const Navbar = (props) => {
    const [showMenu, setShowMenu] = useState(false)
    const handleClick = () => setShowMenu(!showMenu);
    
  
    return (
        <>
            <nav className={`navbar ${props.page}`}>

                <div className="navbar-container">
                    <div className="nav-top-left">

                    </div>
                    <div className={!showMenu ? "nav-top" : "nav-top active"}>
                        
                        <ul className='nav-menu'>
                            <li className="nav-item"> 
                                    <NavLink to='/' activeclassname="active" className="nav-links" onClick={handleClick}>
                                        about
                                    </NavLink>
                            </li>
                            <li className="nav-item"> 
                                <NavLink to='/contact' activeclassname="active" className="nav-links" onClick={handleClick}>
                                    contact
                                </NavLink>
                            </li>
                        </ul>

                        <div className="mobile-menu-content">
                            <img className="mobile-menu-logo" src={require("../../../images/mobile-menu-brand.png")} alt="" />
                            <p>(+852) 5482 4780</p>
                            <Link to="/" className="mobile-menu-icon">
                                <img src={require("../../../images/mobile-menu-icon.png")} alt="" />
                            </Link>
                        </div>

                        <div className="mobile-menu-close" onClick={handleClick}></div>
                    </div>
                    <div className="mobile-menu-button" onClick={handleClick}></div>
                </div>
                <div className="navbar-container ">
                    <div className="nav-left">
                        <Link to="/" className="navbar-logo">
                            <img src={Logo} alt="Molto Digital Limited" />
                        </Link>
                    </div>  
                    <div className="nav-right"> 
                        <div className="nav-top">    
                        </div>
                        <div className="nav-bottom">
                            <div className="nav-bottom-left">
                                Molto Digital Limited is a multi-disciplinary design studio.
                            </div>
                            <div className="nav-bottom-right">
                                <div className="nav-brand">
                                    <img src={brand} alt="Moltodigital-Brand"/>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </nav>
        </>
    )
}

export default Navbar
