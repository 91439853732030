import React from 'react'
import Home from '../../components/Home'
import Navbar from '../../components/AllNavbar/Navbar';
import Footer from '../../components/Footer';

const home = () => {
    return (
        <div>
            <Navbar/>
            <Home />
            <Footer page="home" />
        </div>
    )
}

export default home
