import React from 'react'
import Contact from '../../components/Contact'
import Navbar from '../../components/AllNavbar/Navbar';
import Footer from '../../components/Footer';

const contact = () => {
    return (
        <div>
            <Navbar page="contact"/>
            <Contact />
            <Footer page="contact"/>
        </div>
    )
}

export default contact
