import './App.css';
import React , {useEffect} from 'react';
import {BrowserRouter as Router, Routes , Route} from 'react-router-dom';
import Home from './pages/Home/home';
import Contact from './pages/Contact/contact';
import GoToTop from './GoToTop';
import './mobile.css'

function App() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
      <Router>
        <Routes >
          <Route path='/' element={< Home />}/>
          <Route path='/contact' element={< Contact />}/>
          <Route path='*' element={< Home />}/>
        </Routes >
        <GoToTop />
      </Router>
      


  );
}

export default App;
